'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function ($) {
	var htmlHasClass = function htmlHasClass(_class) {
		return $('html').hasClass(_class);
	};
	var siteName = 'itrade';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|localhost|192\\.168\\.|test2.sh-create\\.co\\.jp)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (var i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (var _i = 0; _i < ajaxLen; _i++) {
					ajaxArrayList[_i] = $.ajax({
						type: 'GET',
						url: ajaxArray[_i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					if (args[1] === 'success') {
						// includeが1個だけの場合
						var position = $(args[0]).filter(':first').attr('class').split(' ');
						for (var j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(args[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					} else {
						for (var _i2 = 0; _i2 < args.length; _i2++) {
							var result = args[_i2];
							var _position = $(result[0]).filter(':first').attr('class').split(' ');

							for (var _j = 0; _j < _position.length; _j++) {
								if (_position[_j].match(regExp)) {
									_position = _position[_j];
									break;
								}
							}
							$('.' + _position).html(result[0]).children().unwrap();

							console.log('Succeeded to read the include file!:', _position);
						}
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						pos -= 100;
					} else {
						pos -= 130;
					}
				}
				if (FUNCTIONS.va.pathname !== '/ir/faq/') {
					$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
				}
			}
		},
		easeScrollHash: function easeScrollHash() {
			$(window).on('load', function () {
				if (location.hash) {
					$('html, body').css('display', 'none');
					setTimeout(function () {
						$('html, body').css('display', 'block');
						var _hash = '#' + location.hash.split('#')[1];
						var pos = $(_hash).offset().top;
						if ($(window).width() < 768) {
							pos -= 100;
						} else {
							pos -= 130;
						}
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				}
			});
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				// var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		headerGnav: function headerGnav() {
			$('.header-nav-main').find('a').each(function (idx, ele) {
				var target = $(ele).attr('data-target');

				$(ele).on('mouseenter', function () {
					$('.header-nav-main').find('a').removeClass('is-hover');
					$('.header-menu').each(function (idx2, ele2) {
						if ($(ele2).attr('data-item') === target) {
							$(ele2).addClass(a);
							setTimeout(function () {
								$(ele2).addClass(v);
							});
							$('.header-nav-main').find('a[data-target=' + target + ']').addClass('is-hover');
						} else if ($(ele2).hasClass(a)) {
							$(ele2).removeClass(v);
							setTimeout(function () {
								$(ele2).removeClass(a);
							}, 300);
						}
					});
				});
			});
			$('.header').on('mouseleave', function () {
				$('.header-nav-main').find('a').removeClass('is-hover');
				$('.header-menu.is-visible').removeClass(v);
				setTimeout(function () {
					$('.header-menu.is-active').removeClass(a);
				}, 300);
			});
		},
		headerHamburger: function headerHamburger() {
			var $hamburger = $('.header-hamburger');
			var $nav = $('.header-nav');
			var scrollTopNow = void 0;

			var fixedOnContainer = function fixedOnContainer() {
				scrollTopNow = $(window).scrollTop();
				$('.container').css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				$('body').addClass(f);
			};
			var fixedOffContainer = function fixedOffContainer() {
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
			};

			// ハンバーガーメニュー
			$hamburger.on('click', function (e) {
				$(e.currentTarget).toggleClass(a);
				if ($nav.hasClass(a)) {
					$nav.removeClass(v).removeClass(a);
					fixedOffContainer();
				} else {
					fixedOnContainer();
					$nav.addClass(a);
					setTimeout(function () {
						$nav.addClass(v);
					});
				}
			});
		},
		headerCurrent: function headerCurrent() {
			$('.header-nav-main').find('a').each(function (idx, ele) {
				if (FUNCTIONS.va.pathname.indexOf($(ele).attr('href')) === 0) {
					if (!(FUNCTIONS.va.pathname.indexOf('/irnews/') !== -1 && $(ele).attr('href') === '/ir/')) {
						$(ele).addClass(a);
					}
				}
			});
		},
		sideNavCurrent: function sideNavCurrent() {
			$('.mod-sideNav-nav').find('a').each(function (idx, ele) {
				if (FUNCTIONS.va.pathname === $(ele).attr('href')) {
					$(ele).addClass(a);
					$(ele).closest('.__item').addClass(a);
				}
			});
		},
		tabSwitch: function tabSwitch() {
			var $tab = $('.js-tab');
			var $content = $('.js-tab-content');
			if ($('.js-tab').length) {

				// ハッシュで指定
				var locHash = location.hash;
				if (locHash.length > 0) {
					var hash = locHash.split('#')[1],
					    id = void 0;

					if (FUNCTIONS.va.pathname === '/ir/faq/') {
						id = hash; // QuestionID
						hash = hash.slice(0, 2); // タブカテゴリ
					}

					$tab.each(function (idx, ele) {
						$(ele).removeClass(a);
						var dataSwitch = $(ele).attr('data-target');
						if (dataSwitch.match(hash)) {
							$(ele).addClass(a);
						}
					});

					$content.each(function (idx, ele) {
						$(ele).removeClass(a).removeClass(v);
						var dataTarget = $(ele).attr('data-target');
						if (dataTarget.match(hash)) {
							$(ele).addClass(a);
							setTimeout(function () {
								$(ele).addClass(v);
							}, '200');
						}
					});

					if (FUNCTIONS.va.pathname === '/ir/faq/') {
						$('#' + id).find('.js-toggle').addClass(a);
						$('#' + id).find('.js-toggleBlock').css('display', 'block');
						setTimeout(function () {
							var pos = FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp ? $('#' + id).offset().top - 100 : $('#' + id).offset().top - 130;
							$('html,body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
						}, '300');
					}
				}

				// クリック時処理
				$tab.on('click', function (e) {
					$tab.each(function (idx, ele) {
						$(ele).removeClass(a);
					});
					$(e.currentTarget).addClass(a);

					$content.each(function (idx, ele) {
						$(ele).removeClass(a).removeClass(v);
						if (FUNCTIONS.va.pathname === '/ir/faq/') {
							// FAQはアコーディオンも閉じる
							$(ele).find('.js-toggle').removeClass(a);
							$(ele).find('.js-toggleBlock').css('display', 'none');
						}
					});

					var target = $(e.currentTarget).attr('data-target');
					var $targetContent = void 0;
					if (target === 'all') {
						$targetContent = $('.js-tab-content');
					} else {
						$targetContent = $('.js-tab-content[data-target=' + $(e.currentTarget).attr('data-target') + ']');
					}
					$targetContent.addClass(a);
					setTimeout(function () {
						$targetContent.addClass(v);
					}, '200');
				});
			}
		},
		chanceChallengeSlider: function chanceChallengeSlider() {
			var arrowPrev = '<button type="button" class="slick-prev"><svg><use xlink:href="/common/svg/sprite.svg#arrow_right2" /></svg></button>';
			var arrowNext = '<button type="button" class="slick-next"><svg><use xlink:href="/common/svg/sprite.svg#arrow_right2" /></svg></button>';
			var startNum = 0;
			switch (FUNCTIONS.va.pathname.split('/')[2]) {
				case '01':
					startNum = 2;break;
				case '02':
					startNum = 3;break;
				case '03':
					startNum = 5;break;
				case '04':
					startNum = 4;break;
				case '05':
					startNum = 1;break;
				default:
					break;
			};
			$('.chance-other .__slider').slick({
				autoplay: false,
				slidesToShow: 3,
				slidesToScroll: 1,
				infinite: true,
				dots: false,
				arrows: true,
				prevArrow: arrowPrev,
				nextArrow: arrowNext,
				initialSlide: startNum,
				responsive: [{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2
					}
				}]
			});
		},
		footerChanceBanner: function footerChanceBanner() {
			if (FUNCTIONS.va.window.width >= FUNCTIONS.va.device.desktop) {
				$(window).scroll(function (e) {
					var $footer = $('.footer');
					var thisScroll = $(e.currentTarget).scrollTop();
					var pagetopPos = $(e.currentTarget).height() + $footer.height() + thisScroll + 100;

					if ($footer.offset().top + $footer.height() <= pagetopPos) {
						$('.footer-chanceBanner').addClass(a);
					} else {
						$('.footer-chanceBanner').removeClass(a);
					}
				});
			}
		},
		lazyload: function lazyload() {
			if ($(".js-lazy-img").length) {
				$(".js-lazy-img").lazyload();
			}
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.headerGnav();
			_this.headerHamburger();
			_this.headerCurrent();
			_this.sideNavCurrent();
			_this.tabSwitch();
			_this.lazyload();

			if (FUNCTIONS.va.pathname.split('/')[1] === 'chance_challenge' && FUNCTIONS.va.pathname.split('/')[2]) {
				_this.chanceChallengeSlider();
			}
			if (FUNCTIONS.va.pathname.split('/')[2] && FUNCTIONS.va.pathname.split('/')[1] !== 'inquiry') {
				_this.footerChanceBanner();
			}
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);